import { ResolveFn } from '@angular/router'

export const languageResolver: ResolveFn<{ [key: string]: string | null }> = (route, state) => {
  const lang = route.url?.[0]?.path
  const params: { [key: string]: string | null } = {}
  route.paramMap.keys.forEach(key => {
    params[key] = route.paramMap.get(key)
    console.log(params[key])
  })
  params['language'] = lang
  console.log(params)
  return params
}



// import { Injectable } from '@angular/core'
// import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
// import { Observable, of } from 'rxjs'

// export class languageResolverResolver: ResolveFn<boolean> = (route, state) => {
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     const language = route.paramMap.get('language')
//     return of({ language })
//   }
// }
