import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { BaseModel } from '../models/base-model';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { FilterOptionsService } from 'src/app/course-library/services/filter-options.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  apiUrl = `${environment.apiUrl}/users`;
  boApiUrl = `${environment.apiUrl}/billing_organizations`;
  boUserApiUrl = `${environment.apiUrl}/billing_organization_users`;
  lpApiUrl = `${environment.apiUrl}/landing_page`;
  constructor(
    private router: Router,
    private http: HttpClient,
    private fltrService: FilterOptionsService,
    private storage: LocalStorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      this.storage.getItem('currentUser')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  removeCurrentUser() {
    this.storage.setItem('currentUser', null);
    this.storage.setItem('accessToken', null);
    this.storage.removeItem('accessToken');
    this.storage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }

  setAndSaveCurrentUser(user: User) {
    this.storage.setItem('currentUser', user);
    this.currentUserSubject.next(user);
  }

  lpCheckPasscode(params) {
    return this.http.post(`${this.lpApiUrl}/check_passcode`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  lpGetOrgsAndSubOrgs() {
    return this.http.get(`${this.lpApiUrl}/get_orgs_and_sub_orgs`).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  getOrganizationName(params) {
    return this.http.post(`${this.apiUrl}/get_organization_name`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  getBillingOrganizationName(params) {
    return this.http.post(`${this.boApiUrl}/get_name`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  getSchoolName(params) {
    return this.http.post(`${this.apiUrl}/get_school_name`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  lpGetOrgAndSubOrgs(params) {
    return this.http.get(`${this.lpApiUrl}/get_org_and_sub_orgs`, { params: params })
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  getSubOrganizations(params) {
    return this.http.post(`${this.apiUrl}/get_suborganizations`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  checkIfPhoneOrEmailExists(params) {
    return this.http.post(`${this.apiUrl}/check_if_phone_or_email_exists`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  signUp(params) {
    return this.http.post<any>(`${this.apiUrl}/create`, params).pipe(
      catchError(this.handleError),
      map((res) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  signUpWithEmail(params) {
    return this.http.post<any>(`${this.apiUrl}/create_with_email`, params).pipe(
      catchError(this.handleError),
      map((res) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  signUpOrg(params) {
    return this.http.post<any>(`${this.apiUrl}/org_create_user`, params).pipe(
      catchError(this.handleError),
      map((res) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  signUpBillingOrg(params) {
    return this.http.post<any>(`${this.boUserApiUrl}/create_user`, params).pipe(
      catchError(this.handleError),
      map((res) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  login(params) {
    return this.http.post(`${this.apiUrl}/login`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  loginSso(params) {
    return this.http.post(`${this.apiUrl}/login_sso`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  verifyTfaCode(params) {
    return this.http.post(`${this.apiUrl}/verify_2fa`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulResponse(res);
      })
    );
  }

  getUser() {
    return this.http.get(`${this.apiUrl}/me`).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  retrieveProfile(params) {
    return this.http
      .get(`${this.apiUrl}/get_user_for_edit_profile?id=${params.id}`)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  updateProfile(params) {
    return this.http.post(`${this.apiUrl}/update_profile`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulUpdateResponse(res);
      })
    );
  }

  checkEmail(email) {
    const params = { email: `${email}` };
    return this.http.post(`${this.apiUrl}/check_email`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  // takes :first_name, :last_name, :phone
  forgotUserName(params) {
    return this.http.post(`${this.apiUrl}/forgot_username`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  // takes :email
  sendPasswordResetEmail(params) {
    return this.http
      .post(`${this.apiUrl}/send_password_reset_email`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // takes :token
  verfiyPasswordResetToken(params) {
    return this.http
      .post(`${this.apiUrl}/verify_password_reset_token`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // takes :email and :password
  updatePassword(params) {
    return this.http.post(`${this.apiUrl}/update_password`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  // takes :token, :email, and :password
  resetPassword(params) {
    return this.http.post(`${this.apiUrl}/reset_password`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  verifyEmail(params: any) {
    return this.http.post(`${this.apiUrl}/verify_email`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  resendVerificationEmail(params) {
    return this.http
      .post(`${this.apiUrl}/resend_verification_email`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  // Comment for commit
  setUsersSubOrg(params) {
    return this.http.post(`${this.apiUrl}/set_users_sub_org`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  setPopUpAck(params) {
    return this.http.post(`${this.apiUrl}/set_pop_up_ack`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  setDemoAck(params) {
    return this.http.post(`${this.apiUrl}/set_demo_ack`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  setTutorialAck(params) {
    return this.http.post(`${this.apiUrl}/set_tutorial_ack`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulUpdateResponse(res);
      })
    );
  }

  // handle_org_fv_series_create_user
  handleOrgFvSeriesCreateUser(params) {
    return this.http.post(`${this.apiUrl}/handle_org_fv_series_create_user`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleSuccessfulResponse(res)
      })
    )
  }

  logout() {
    const ssoUser = this.storage.getItem('ssoUser');
    if (ssoUser) {
      this.logoutSso();
    } else {
      this.storage.removeItem('accessToken');
      this.storage.removeItem('currentUser');
      this.fltrService.remove();
      this.currentUserSubject.next(null);
      this.router.navigate(['/login']);
    }
  }

  logoutSso() {
    this.storage.removeItem('accessToken');
    this.storage.removeItem('currentUser');
    this.storage.removeItem('ssoUser');
    this.fltrService.remove();
    this.currentUserSubject.next(null);
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login'], { queryParams: { ssoLogout: true } });
  }

  // Stripe Methods
  createStripeCustomer(params) {
    return this.http.post(`${this.apiUrl}/create_stripe_customer`, params).pipe(
      catchError(this.handleError),
      map((res: BaseModel) => {
        return this.handleBaseResponse(res);
      })
    );
  }

  addCardToStripeCustomer(params) {
    return this.http
      .post(`${this.apiUrl}/add_card_to_stripe_customer`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }

  retrievePaymentSources(params) {
    return this.http
      .post(`${this.apiUrl}/retrieve_payment_sources`, params)
      .pipe(
        catchError(this.handleError),
        map((res: BaseModel) => {
          return this.handleBaseResponse(res);
        })
      );
  }
  // End of Stripe Methods

  // 401 erorr handling
  removeCurrentUserAndRoute401() {
    this.storage.setItem('currentUser', undefined);
    this.storage.setItem('accessToken', undefined);
    this.storage.setItem('currentSearch', undefined);
    this.storage.setItem('noSubOrgs', undefined);
    this.storage.setItem('orgName', undefined);
    this.storage.removeItem('currentUser');
    this.storage.removeItem('accessToken');
    this.storage.removeItem('currentSearch');
    this.storage.removeItem('noSubOrgs');
    this.storage.removeItem('orgName');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login', { login: true }]);
    return true;
  }

  // End of 401 error handling

  // Beginning of Private Methods
  private handleSuccessfulResponse(res) {
    // login successful if there's a success, currentUser, and securityToken in the response
    if (res && res.success && res.payload && res.payload === 'admin') {
      return { success: true, admin: true };
    } else {
      if (res && res.success && res.payload.token) {
        const newUser = new User(res.payload.user);
        this.currentUserSubject.next(newUser);
        const token = res.payload.token;
        this.storage.setItem('accessToken', token.value);
        this.storage.setItem('currentUser', newUser);
        if (res.payload.org_name) {
          this.storage.setItem('noSubOrgs', res.payload.no_sub_orgs);
          this.storage.setItem('orgName', res.payload.org_name);
        }
        return { success: true, user: newUser };
      } else if (res && !res.success && res.message) {
        return { success: false, errorMsg: res.message };
      } else {
        return { success: false };
      }
    }
  }

  private handleSuccessfulUpdateResponse(res) {
    // login successful if there's a success, currentUser, and securityToken in the response
    if (res && res.success) {
      const newUser = new User(res.payload);
      this.currentUserSubject.next(newUser);
      this.storage.setItem('currentUser', newUser);
      return newUser;
    } else {
      throwError(res.errors);
      return res;
    }
  }

  private handleBaseResponse(res: BaseModel) {
    if (res.success) {
      return res.payload;
    } else {
      throwError(res.errors);
      return res;
    }
  }

  private handleError(error) {
    let errorMessage = '';
    // server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error['error']['errors']}`;
    return throwError({ status: error.status, msg: errorMessage });
  }
}
