import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary, FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faSpinner, faAngleLeft, faAngleRight, faPlus, faMinus, faStar as fasStar, faStarHalfAlt, faArrowRight, faUpload, faVideo,
  faEdit, faPen, faTrashAlt, faSearch, faUser, faKey, faEye, faEyeSlash, faSignInAlt, faSignOutAlt, faUserPlus, faUserMinus,
  faCircle, faMap, faMapMarked, faMapMarkerAlt, faWheelchair, faPaw, faChild, faCheck, faBars, faWindowClose, faLock,
  faEnvelope, faBook, faSuitcase, faLongArrowAltRight, faMoneyBill, faCaretLeft, faCaretRight, faDownload, faTimes, faQuestion,
  faTrash, faFile, faInfoCircle, faEllipsisV, faSuitcaseRolling, faBan, faExternalLinkAlt, faChartBar, faChevronDown, faCog, faCalendar, faStoreSlash
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF, faInstagram, faTwitter, faPinterest, faPinterestP, faLinkedin, faMicrosoft,
  faFacebookSquare, faInstagramSquare, faTwitterSquare, faPinterestSquare, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons'
import { faStar as farStar, faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    FaIconComponent
  ]
})
export class FaIconModule {
  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(
      faSpinner, faAngleLeft, faAngleRight, faPlus, faMinus, fasStar, faStarHalfAlt, faArrowRight, faUpload, faVideo, faTimes,
      faEdit, faPen, faTrashAlt, faSearch, faUser, faKey, faEye, faEyeSlash, faSignInAlt, faSignOutAlt, faUserPlus, faUserMinus, faCircle, farCircle,
      faMap, faMapMarked, faMapMarkerAlt, faWheelchair, faPaw, faChild, faCheck, faBars, faWindowClose, faEnvelope, faBook, faSuitcase,
      faLongArrowAltRight, faCheck, faMoneyBill, faCaretLeft, faCaretRight, faDownload, faTrash, faFile, faInfoCircle, faEllipsisV, faChartBar,
      faLinkedin, faYoutubeSquare, faCalendar, faQuestion, faLock, faBan, faStoreSlash, faMicrosoft,
      faFacebookSquare, faInstagramSquare, faTwitterSquare, faPinterestSquare, faFacebookF, faInstagram, faTwitter, faPinterest, faSuitcaseRolling,
      faLongArrowAltRight, faCheck, faMoneyBill, faCaretLeft, faCaretRight, faDownload, faTrash, faFile, faInfoCircle, faBan, faExternalLinkAlt, faCog,
      faFacebookSquare, faInstagramSquare, faTwitterSquare, faPinterestSquare, faFacebookF, faInstagram, faTwitter, faPinterest, faPinterestP, farStar, faChevronDown
    );
  }
 }
