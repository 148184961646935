import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastFourPipe } from './pipes/last-four.pipe';
import { RestoreScrollingDirective } from './directives/restore-scrolling.directive';
import { ScrollToItemDirective } from './directives/scroll-to-item.directive';
import { HorizontalParallaxDirective } from './directives/horizontal-parallax.directive';
import { NavMenuDirective } from './directives/nav-menu.directive';
import { BackgroundChangeOnScrollDirective } from './directives/background-change-on-scroll.directive';
import { TogglePasswordDirective } from './directives/toggle-password.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SeriesTagDirective } from './directives/series-tag.directive';

@NgModule({
  declarations: [
    RestoreScrollingDirective,
    ScrollToItemDirective,
    HorizontalParallaxDirective,
    NavMenuDirective,
    BackgroundChangeOnScrollDirective,
    TogglePasswordDirective,
    SeriesTagDirective
  ],
  imports: [CommonModule],
  exports: [
    RestoreScrollingDirective,
    ScrollToItemDirective,
    HorizontalParallaxDirective,
    NavMenuDirective,
    BackgroundChangeOnScrollDirective,
    TogglePasswordDirective,
    SeriesTagDirective
  ]
})
export class SharedModule {}
